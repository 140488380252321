<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Mã sinh viên"
                  label-for="code_student"
                >
                  <b-form-input
                    id="student-code"
                    v-model="filter.code_student"
                    name="code_student"
                    placeholder="Nhập mã sinh viên"
                    @keydown.enter.native="getReceiptByCode"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Số phiếu"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="currentReceipt.code"
                    name="code"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-show="!!currentReceipt.code"
                cols="4"
                style="color: #0ca940; text-align: center"
              >
                <b>Đã nộp khoản thu khác</b><i
                  style="font-size: 54px"
                  class="lar la-check-circle"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Họ tên"
                  label-for="last-name"
                >
                  <b-form-input
                    id="last-name"
                    style="width: 60%; display: inline-block"
                    :value="currentReceipt.last_name"
                    name="last-name"
                    :disabled="true"
                  />
                  <b-form-input
                    id="first-name"
                    style="float: right; width: 38%"
                    :value="currentReceipt.first_name"
                    name="first-name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Ngày nộp"
                  label-for="day"
                >
                  <flat-pickr
                    v-model="filter.day"
                    :config="config"
                    class="form-control"
                    placeholder="Thời gian bắt đầu"
                    name="start_at"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Giới tính"
                  label-for="gender"
                >
                  <b-form-input
                    id="gender"
                    :value="getGender(currentReceipt.gender)"
                    name="gender"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Ngày sinh"
                  label-for="birthday"
                >
                  <b-form-input
                    id="birthday"
                    :value="currentReceipt.birthday"
                    name="birthday"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Nơi sinh"
                  label-for="birth-place"
                >
                  <b-form-input
                    id="birth-place"
                    :value="currentReceipt.birth_place"
                    name="birth-place"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Khóa học"
                  label-for="course-name"
                >
                  <b-form-input
                    id="course-name"
                    :value="currentReceipt.course_name"
                    name="course-name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Lớp"
                  label-for="class-name"
                >
                  <b-form-input
                    id="class-name"
                    :value="currentReceipt.class_name"
                    name="class-name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Ngành"
                  label-for="major-name"
                >
                  <b-form-input
                    id="major-name"
                    :value="currentReceipt.major_name"
                    name="major-name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Loại đối tượng"
                  label-for="policies-name"
                >
                  <b-form-input
                    id="policies-name"
                    :value="currentReceipt.policies_name"
                    name="policies-name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Lý do nộp"
                  label-for="reason"
                >
                  <b-form-input
                    id="reason"
                    v-model="currentReceipt.reason"
                    name="reason"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Tổng tiền"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    :value="
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(currentReceipt.amount)
                    "
                    name="amount"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <b-form-group
                  label="Tổng tiền bằng chữ"
                  label-for="amount-word"
                >
                  <b-form-input
                    id="total-exemption"
                    v-model="currentReceipt.amount_word"
                    name="total-exemption"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" />
              <b-col cols="6">
                <div class="text-right">
                  <b-button
                    v-show="!currentReceipt.code && creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onCreate"
                  >
                    <span class="text-nowrap text-right">Lưu phiếu thu</span>
                  </b-button>
                  <b-button
                    v-show="!!currentReceipt.code"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onPrint"
                  >
                    <span class="text-nowrap text-right">In phiếu thu</span>
                  </b-button>
<!--                  <b-button
                    v-show="!!currentReceipt.code"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.historyModal
                    variant="primary"
                    style="margin-right: 5px"
                  >
                    <span class="text-nowrap text-right">Chi tiết</span>
                  </b-button>-->
                </div>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px">
              <b-col cols="12">
                <div>
                  <b-table
                    striped
                    hover
                    :items="revenue_items"
                    :fields="revenue_fields"
                  >
                    <template #cell(amount)="data">
                      {{
                        new Intl.NumberFormat("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        }).format(data.item.amount)
                      }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <vue-html2pdf
      ref="html2PdfRef"
      :show-layout="controlValue.showLayout"
      :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <pdf-content
        slot="pdf-content"
        :data="itemPrint"
      />
    </vue-html2pdf>
    <HistoryReceipt
      ref="historyFormRef"
      :student-classes-id="currentReceipt.student_classes_id"
    />
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BTable,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { GENDERS } from '@/const/status'
import VNnum2words from 'vn-num2words'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import PdfContent from '@/views/finance/receipt/PdfContent.vue'
import HistoryReceipt from '@/views/finance/receipt/History.vue'
import VueHtml2pdf from 'vue-html2pdf'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { vn } from 'flatpickr/dist/l10n/vn.js'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'ReceiptKTK',
  directives: {
    Ripple,
  },
  components: {
    HistoryReceipt,
    BTable,
    BFormInput,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    PdfContent,
    VueHtml2pdf,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      isCreateReceipt: true,
      filter: {
        code_student: null,
        day: new Date(),
      },
      currentReceipt: {
        course_semester_id: null,
        student_classes_id: null,
        reason: null,
        amount: null,
        amount_word: null,
        total: null,
        total_exemption: null,
        code: null,
        id: null,
        last_name: null,
        first_name: null,
        gender: null,
        birthday: null,
        birth_place: null,
        class_name: null,
        major_name: null,
        course_name: null,
        policies_name: null,
        revenue_name: null,
        collecter_id: null,
        collection_time: null,
        status: 1,
      },
      revenue_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'revenue_name', label: 'Loại khoản thu khác' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_items: [],
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
        // minDate: 'today',
      },
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: false,
        previewModal: true,
        paginateElementsByHeight: 1200,
        manualPagination: false,
        filename: 'PhieuThuHocPhi',
        pdfQuality: 2,
        pdfFormat: 'a5',
        pdfOrientation: 'landscape',
        pdfContentWidth: '800px',
      },
      itemPrint: {
        name: '',
        address: '',
        content: '',
        amount: '',
        amount_word: '',
        date: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      listStudentInfo: 'receipt/listRevenuesKTKTotal',
      listRevenuesKTK: 'receipt/listRevenuesKTK',
    }),
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'PhieuThu.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RECEIPT)
    },
  },
  async created() {
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getReceiptKTK: 'receipt/getReceiptKTK',
      createReceiptKTK: 'receipt/createReceiptKTK',
    }),
    async onCreate() {
      try {
        this.currentReceipt.code = `#${Date.now()}`
        this.currentReceipt.collecter_id = getUser().employeeId
        this.currentReceipt.collection_time = this.filter.day
        this.currentReceipt.status = 2
        await this.createReceiptKTK(this.currentReceipt)
        this.isCreateReceipt = false
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tạo mới phiếu thu thành công',
            icon: 'XCircleIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getReceiptByCode() {
      if (this.filter.code_student == null || this.filter.day === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mã số sinh viên hoặc ngày nộp đang để trống',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } else {
        await this.getReceiptKTK(this.filter)
        this.currentReceipt = {
          ...this.currentReceipt,
          ...this.listStudentInfo[0],
        }
        this.currentReceipt.amount_word = this.getAmountWord(
          this.currentReceipt.amount,
        )
        this.revenue_items = this.listRevenuesKTK
        if (this.currentReceipt.collection_time != null) {
          this.filter.day = this.currentReceipt.collection_time
        }
      }
    },
    getGender(id) {
      if (id !== null) {
        const rs = GENDERS.find(gender => gender.value === id)
        return (rs === undefined ? 'Không xác định' : rs.label)
      }
      return null
    },
    getAmountWord(amount) {
      if (amount !== null) {
        // eslint-disable-next-line camelcase
        const amount_word = VNnum2words(amount)
        return `${
          amount_word.charAt(0).toUpperCase() + amount_word.slice(1)
        } đồng`
      }
      return null
    },
    async onPrint() {
      this.isLoading = true
      try {
        this.itemPrint.name = getUser().fullName
        this.itemPrint.address = getUser().department_name
        this.itemPrint.content = `Thu tiền ${this.currentReceipt.reason.toLowerCase()} của sinh viên ${this.currentReceipt.last_name} ${this.currentReceipt.first_name} - Lớp ${this.currentReceipt.course_name} ${this.currentReceipt.class_name}`
        this.itemPrint.amount = new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(this.currentReceipt.amount)
        this.itemPrint.amount_word = this.getAmountWord(this.currentReceipt.amount)
        this.itemPrint.date = `Ngày ${this.currentReceipt.collection_time.substring(8, 10)} tháng ${this.currentReceipt.collection_time.substring(5, 7)} năm ${this.currentReceipt.collection_time.substring(0, 4)}`
        this.$refs.html2PdfRef.generatePdf()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
